:root {
    --ek-light: #f2fbfb;
    --ek-main: #27b9ad;
    --ek-accent: #67e9e0;
    --ek-dark: #313534;
    --ek-highlight: #e96770;
}

.ek-wrapper {
    height: 100%;
    width: 100%;
    background-color: var(--ek-light);
    z-index: -5;
    font-family: Cabin;
    color: var(--ek-dark);
    overflow-x: hidden;
}

.ek-wrapper a {
    color: var(--ek-dark);
    text-decoration: none;
}

.ek-wrapper p {
    margin: 0;
}

.ek-wrapper button:hover, .ek-wrapper a:hover, .ek-wrapper input:hover {
    cursor: pointer;
}

// Nav

.ek-nav {
    background-color: #212020;
    // height: 15em;
    width: 100%;
    // position: fixed;
    top: 0;
    z-index: 900;
    position: fixed;
    // padding: 0 3em;
    transition: all .2s ease-in-out;
}

.scroll-up {
    transform: translateY(-100%);
}

.main-nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 3em;

    @media screen and (max-width: 980px) {
        justify-content: center;
        border-bottom: 5px solid var(--ek-main)
    }
}

.ek-logo {
    height: 3em;

    @media screen and (max-width: 980px) {
        margin: 0 1em;
    }
}

.cart-modal {
    position: fixed;
    background-color: var(--ek-light);
    z-index: 9900;
    border: 2px solid var(--ek-accent);
    right: 10%;
    top: 10%;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    padding: 5em 1em;
    box-shadow: 0 0 10px gray;

    @media screen and (max-width: 980px) {
        right: 1em;
        top: 10em;
        width: 50%;
    }
}

.close-cart-btn {
    position: absolute;
    top: 1em;
    left: 1em;
    background-color: var(--ek-main);
    border: none;
    border-radius: 500px;
    color: var(--ek-light);
    font-size: 1em;
    font-weight: 900;
}

.close-cart-btn:hover {
    background-color: #267770;
}

.checkout-btn {
    width: 15em;
    background-color: var(--ek-highlight);
    border: none;
    border-radius: 10px;
    font-size: 1.5em;
    color: var(--ek-light);
    padding: .25em;
    margin: .5em 0;

    @media screen and (max-width: 980px) {
        width: 100%;
    }
}

.total-cart-modal {
    color: var(--ek-main);
    font-weight: 900;
    font-size: 2em;
    margin: .5em 0 !important;
}

.cart-modal-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.cart-modal-item h6 {
    margin: .5em 0;
}

.total-modal-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.empty-cart {
    background-color: transparent;
    border: none;
    color: var(--ek-highlight);
}

.cart-and-account-wrapper {
    display: flex;
}

.ek-cart {
    @media screen and (max-width: 980px) {
        position: absolute;
        top: 1em;
        right: 1em;
    }
}

.ek-cart:hover {
    cursor: pointer;
}

.cart-and-account-wrapper img {
    height: 3em;
    margin: 0 1em;

    @media screen and (max-width: 980px) {
        height: 2em;
    }
}

.search-wrapper {
    width: 80%;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ek-search {
    border: 1px solid var(--ek-main);
    padding: 0em 1em;
    height: 100%;
    width: 70%;
    border-radius: 10px 0 0px 10px;
}

.ek-search:focus {
    outline: none;
}

.search-button {
    background-color: var(--ek-highlight);
    border: 1px solid var(--ek-dark);
    height: 110%;
    width: 3em;
    border-radius: 0px 10px 10px 0px;
}

.search-button img {
    height: 50%;
}

.sub-nav {
    display: flex;
    justify-content: flex-start;
    background-color: var(--ek-dark);
    padding: .5em 0;
    position: absolute;
    bottom: -2em;
    width: 100%;
    // border-bottom: 1px solid var(--ek-dark);
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.3);
}

.sub-nav a {
    color: var(--ek-light);
    margin: 0 3em;
    text-transform: uppercase;
}

// Product

.ek-product-wrapper {
    padding: 10em 3em 3em 3em;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    grid-column-gap: 1em;

    @media screen and (max-width: 980px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 8em 3em 3em 3em;
    }
}

.ek-product-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.display-price {
    color: var(--ek-highlight);
    font-size: 2em;
    margin: .5em 0;
}

.breadcrumbs {
    color: var(--ek-dark);
    margin: .2em 0;
    font-size: 1em;
    text-transform: uppercase;
    font-family: Montserrat;
}

.ek-product-images {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.main-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    // height: 50%;
    // max-height: 90%;
    margin: 1em 0 2em 0;
}

.sub-images {
    display: flex;
    justify-content: center;
}

.sub-images img {
    height: 3em;
    width: 3em;
    object-fit: cover;
    border: 2px solid var(--ek-main);
    padding: .5em;
    margin: 0 2em;
    border-radius: 500px;
}

.sub-images img:hover {
    cursor: pointer;
}

.ek-product-information {
    background-color: var(--ek-light);
    padding: 1.5em;
}

.ek-product-information {
    color: var(--ek-dark);
}

.product-title {
    margin-top: 0;
    color: var(--ek-dark);
    font-size: 3em;

    @media screen and (max-width: 980px) {
        margin: 1em 0;
    }
}

body .product-brief-description {
    color: var(--ek-accent);
    margin: 1em 0;
}

.product-options label {
    margin: 1.5em 0;
}

.option {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
}

.option .type-label {
    font-weight: 900;
    margin: 0 0 .5em 0;
}

.radio-btn {
    margin: 0 1em 0 0;
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--ek-light);
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--ek-highlight);
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid var(--ek-light);
}

.ek-product-add-to-cart {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
}

.cart-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // background-color: var(--ek-accent);
    border: 2px solid var(--ek-main);
    padding: 2em;
    position: sticky;
    // right: 0;
    top: 10%;
    // height: 3em;
    width: 70%;
    // box-shadow: 0 0 20px rgba(209, 209, 209, 0.411);
    border-radius: 15px;
}

.cart-wrapper p {
    font-size: 1.5em;
}

.quantity-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 3em 0;
}

.quantity-wrapper input {
    background-color: transparent;
    border: none;
    text-align: center;
    font-size: 3em;
    width: 3em;
    color: var(--ek-dark);
}

.quantity-wrapper input:focus {
    border: none;
    outline: none;
}

.quantity-wrapper button {
    background-color: var(--ek-main);
    color: var(--ek-dark);
    width: 1.5em;
    height: 1.5em;
    border: none;
    font-size: 1.5em;
    border-radius: 50%;
    color: var(--ek-light);

    @media screen and (max-width: 980px) {
        border-radius: 50px;
        padding: 0;
        width: 2em;
    }
}

.add-to-cart-btn  {
    background-color: var(--ek-highlight);
    color: var(--ek-light);
    border: none;
    width: 100%;
    font-size: 1.5em;
    text-transform: uppercase;
    padding: .5em 1em;
    border-radius: 100px;
    margin: .5em 0;
}

.add-to-cart-btn:hover, .checkout-btn:hover {
    background-color: #CA414B;
}

.total-price {
    color: var(--ek-highlight);
    font-size: 1em !important;
}

.save-for-later-btn {
    color: var(--ek-dark);
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    font-family: Montserrat;
    font-weight: 900;
    font-size: 1em;
}

// More information

.ek-more-information-block {
    padding: 3em;
}

.ek-more-information-wrapper {
    padding: 1.5em 3em;
    font-size: 1.5em;
    border-radius: 0 10px 10px 10px;
    border: 2px solid var(--ek-main);
    color: var(--ek-light);
    background-color: var(--ek-main);

    @media screen and (max-width: 980px) {
        border-radius: 10px;
        max-height: 45em;
        overflow-y: scroll;
    }
}

.tab-button {
    background-color: var(--ek-light);
    border: 2px solid var(--ek-main);
    // border-bottom-color: var(--ek-light);
    padding: 1em;
    margin: 0 1em -2px 0;
    transition: all .2s ease-in-out;
    border-radius: 10px 10px 0 0;
    color: var(--ek-main);
}

.tab-button.active {
    background-color: var(--ek-main); 
    border-bottom-color: var(--ek-main);  
    color: var(--ek-light);
}

.review-item {
    margin: 1em 0;
}

.customer-review {
    color: var(--ek-dark);
}

.faq-question {
    font-size: 1em;
    color: var(--ek-dark);
    margin: 1em 0;
}

@media screen and (max-width: 980px) {
    .tab-button {
        margin: 1em 0;
        border-bottom-color: var(--ek-main);
        border-radius: 10px;
        min-width: 35%;
        width: 100%;
    }

    .tab-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .ek-more-information-wrapper {
        padding: .5em 1em;
    }
}

// More Like This, Customers also Bought

.ek-similar-products-block, .ek-also-bought-block {
    padding: 3em;
}

.ek-block-title {
    font-size: 3em;

    @media screen and (max-width: 980px) {
        margin: 0 0 1em 0;
    }
}

.ek-more-products-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ek-more-products-wrapper .product {
    height: 20em;
    width: 15%;
    // background-color: var(--ek-accent);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    width: 100%;
    height: auto;
    margin: 0 1em 0 0;
    transition: all .2s ease-in-out;
}

.ek-more-products-wrapper .product:hover {
    transform: scale(1.025);
}

.product h6 {
    font-size: 1em;
    margin: 1.5em 0 .5em 0;
}

.ek-more-products-wrapper .product:last-child {
    margin: 0;
}

.ek-more-products-wrapper .product img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.ek-more-products-wrapper .product:hover {
    cursor: pointer;
}

@media screen and (max-width: 980px) {
    .ek-more-products-wrapper {
        display: block;
    }

    .ek-more-products-wrapper .product {
        margin: 1em 0;
    }
}

// Newsletter

.ek-newsletter-block {
    padding: 3em;
    background-color: var(--ek-main);
    display: flex;
    justify-content: space-evenly;
}

.ek-newsletter-block h3 {
    color: var(--ek-light);
    font-size: 2em;
    margin: 0;
}

.ek-newsletter-block button {
    background-color: var(--ek-dark);
    color: var(--ek-light);
    border: none;
    font-size: 1.5em;
    padding: .5em 1em;
    width: 20%;
    border-radius: 15px;
}

@media screen and (max-width: 980px) {
    .ek-newsletter-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ek-newsletter-block button {
        width: 100%;
    }

    .ek-newsletter-block h3 {
        margin: 0 0 1em 0;
    }
}

// Footer

.ek-footer-block {
    padding: 3em;
}

.ek-footer {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.ek-footer ul {
    list-style: none;
}

.ek-footer ul li:first-child {
    font-weight: 900;
}

.about-social-links.enigma {
    padding: 0;
    margin: 1.5em 0;
    justify-content: flex-start;
    flex-wrap: wrap;
}

@media screen and (max-width: 980px) {
    .ek-footer {
        grid-template-columns: 50% 50%;
        align-items: baseline;
        justify-content: baseline;
    }

    .about-social-links.enigma {
        flex-wrap: wrap;
        padding: 0;
        margin: 1.5em 0;
        width: 100%;
    }

    .ek-footer ul {
        padding: 0 !important;
        margin: 0 !important;
        text-align: center;
    }

    .ek-connect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

// Notice

.ek-notice-block {
    padding: 3em;
    background-color: var(--ek-dark);
    display: flex;
    justify-content: space-around;
    color: var(--ek-accent);
}