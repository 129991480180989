:root {
    --zenn-main: #C4A9F9;
    --zenn-secondary: #5C3CAB;
    --zenn-bg: #2D2D2D;
}

button {
    transition: all .2s ease-in-out;
}

.zenn-gradient-text {
    background-image: linear-gradient(45deg, white, #C4A9F9, #907BD2, #7955D4, #5C3CAB);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent !important;
    font-weight: 700;
}

.zenn-wrapper {
    height: 100%;
    width: 100%;
    background-color: var(--zenn-bg);
    background: rgb(45, 45, 45);
    background: linear-gradient(180deg, rgba(45, 45, 45, 1) 25%, rgba(74, 73, 69, 1) 100%);
    color: white;
    font-family: sans-serif;
    padding: 5em 0;
    overflow-x: hidden;
}

/* Landing Page */

.zenn-title {
    width: 100%;
    font-weight: 900;
    margin: 0 0 2em 0;

    @media screen and (max-width: 980px) {
        width: 100%;
    }
}

.zenn-tagline {
    color: var(--zenn-main);
    margin: 0;
    font-weight: 100;
}

.zenn-landing-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.zenn-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin: 1em 0;

    @media screen and (max-width: 980px) {
        width: 100%;
    }
}

.zenn-button-wrapper a {
    color: white;
    text-decoration: none;
    font-size: 1.25em;
    margin: 0 2em;
    transition: all .2s ease-in-out;

    @media screen and (max-width: 980px) {
        font-size: .8em;
    }
}

.zenn-button-wrapper .zenn-learn-more {
    opacity: 70%;
}

.zenn-button-wrapper .zenn-learn-more:hover {
    opacity: 100%;
}

.zenn-button-wrapper .zenn-shop-now {
    background-color: var(--zenn-main);
    color: var(--zenn-bg);
    padding: .5em .8em;
    border-radius: 12px;
    border: 2px solid transparent;
}

.zenn-button-wrapper .zenn-shop-now:hover,
.zenn-buy-button:hover {
    color: var(--zenn-main);
    border-color: var(--zenn-main);
    background-color: transparent;
}

.down-arrow {
    height: .8em;
}

.zenn-landing-image {
    width: 20%;
    height: auto;
    margin: 2em 0;

    @media screen and (max-width: 980px) {
        // position: absolute;
        width: 80%;
    }
}

#wave1 {
    margin: 3em 0 0 0;
}

#wave2 {
    margin: 0 0 3em 0;
}

.zenn-wave {
    width: 100vw;
    height: auto;
    margin: 0;
}

/* Information Block */

.zenn-information-wrapper {
    display: flex;
    justify-content: center;
    background-color: var(--zenn-main);
    margin: -1em 0 0 0;
}

.zenn-information-block {
    background-color: var(--zenn-main);
    display: grid;
    height: auto;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    margin: -1em 0 0 0;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    padding: 5em 15em;
    width: 50%;

    @media screen and (max-width: 980px) {
        width: 100%;
        padding: 5em 2em 10em 2em;
        grid-template-columns: auto;
        grid-template-rows: 33% 33% 33%;
    }
}

.block, .starting-slide {
    background-color: var(--zenn-bg);
    border-radius: 35px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 1em;
    overflow: hidden;
    flex-direction: column;
}

.block.sleep-secrets,
.block.wake-up-naturally {
    height: 90%;
}

.reveal-slide {
    // display: none;
    background-color: red;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    transform: translateX(-100%);
    transition: all .2s ease-in-out;
}

.reveal-slide.secrets.slide-out, .reveal-slide.naturally {
    transform: translateX(0%);
}

@media screen and (max-width: 980px) {

    .block.sleep-secrets,
    .block.wake-up-naturally,
    .block.sleep-smarter {
        height: 100%;
    }

    .block.wake-up-naturally h4,
    .block.sleep-smarter h4 {
        font-size: 3em !important;
    }

    .block.sleep-secrets h4 span,
    .block.wake-up-naturally h4 span,
    .block.sleep-smarter h4 span {
        font-size: 1em !important;
    }
}

.sleeping-man {
    height: 30em;
    top: 5em;
    position: absolute;

    @media screen and (max-width: 980px) {
        bottom: 0;
        top: auto;
    }
}

.unlock {
    position: absolute;
    top: 1em;
    font-size: 2.5em;
    margin: 0;
}

.sleep-secret-title {
    position: absolute;
    font-size: 3em;
    margin: 0 !important;
    top: 2em;
    right: 1em;
    text-align: left;
    overflow: visible;
    z-index: 10;

    @media screen and (max-width: 980px) {
        top: 20%;
        right: auto;
        text-align: center;
        font-size: 4em !important;
        width: 100%;
    }
}

.block.sleep-secrets {
    color: var(--zenn-main);
}

.block.wake-up-naturally h4 {
    color: var(--zenn-main);
    font-size: 2.5em;
}

.block.wake-up-naturally h4 span {
    color: var(--zenn-main);
    font-size: 2em;
}

.block.sleep-smarter {
    grid-row: span 2;
    grid-column: 1;
    height: 60em;
    flex-direction: column;

    @media screen and (max-width: 980px) {
        grid-row: auto;
        grid-column: auto;
        height: auto;
    }
}

.block.sleep-smarter h4 {
    font-size: 5em;
    margin: 0 0 .5em 0;
}

.block.sleep-smarter .information-image {
    height: 50%;
}

.zenn-arrow {
    position: absolute;
    bottom: 1em;
    right: 1em;
    font-size: 1em;
}

/* Product Block */

.product-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15em;

    @media screen and (max-width: 980px) {
        padding: 0 0 0 3em;
    }
}

.product-block-header h3 {
    font-size: 3em;
    max-width: 40%;
    font-weight: 900;
}

.product-block-header button {
    background-color: transparent;
    border: none;
    color: var(--zenn-main);
    text-align: right;
    height: auto;
    font-size: 2em;
    max-width: 40%;

    @media screen and (max-width: 980px) {
        display: none;
    }
}

.zenn-product-wrapper {
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 980px) {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 50% 50%;
    }
}

.zenn-product {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 980px) {
        margin: 3em 1em;
    }
}

.zenn-product img {
    height: 10em;

    @media screen and (max-width: 980px) {
        height: 8em;
    }
}

.zenn-product button {
    background-color: var(--zenn-main);
    border: 2px solid transparent;
    border-radius: 10px;
    font-size: 1.3em;
    padding: .2em 1em;
    color: var(--zenn-bg);
    /* text-transform: uppercase; */
}

.product-name {
    font-size: 2em;
    margin: 1em 0;
    font-family: 'Hubballi', sans-serif;
    letter-spacing: .1em;
    text-transform: uppercase;

    @media screen and (max-width: 980px) {
        font-size: 1em;
    }
}

.product-description {
    margin: 0em 0;
    opacity: 50%;
}

.product-price {
    font-size: 1.3em;
}

/* Charity Block */

.zenn-charity-block {
    background-color: var(--zenn-main);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 5em 0em;
    /* border-radius: 30px; */
    flex-direction: row;
    padding: 0 0 0 15%;
    height: 30em;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 980px) {
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em 0 2em 15%;
    }
}

.charity-man {
    /* position: absolute; */
    height: 40em;
    right: 15%;

    @media screen and (max-width: 980px) {
        height: 20em;
        position: absolute;
        bottom: -1em;
        right: -5em;
        opacity: 20%;
    }
}

.zenn-charity-block h2 {
    color: var(--zenn-secondary);
    font-size: 5em;
    margin: .2em 0;

    @media screen and (max-width: 980px) {
        font-size: 3em;
    }
}

.zenn-charity-block h6 {
    color: var(--zenn-secondary);
    font-size: 2em;
    margin: 0;
    max-width: 50%;
    opacity: 50%;
}

/* Perks */

.zenn-perks-block {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
}

.zenn-perk {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 20%;
    text-align: center;
}

.zenn-perk img {
    height: 10em;

    @media screen and (max-width: 980px) {
        height: auto;
    }
}

.zenn-perk h4 {
    font-size: 2em;
    font-family: 'Hubballi', sans-serif;
    text-transform: uppercase;
    letter-spacing: .15em;
    margin-top: 0;

    @media screen and (max-width: 980px) {
        font-size: 1em;
    }
}

.zenn-perk p {
    opacity: 50%;
    font-size: 1.15em;

    @media screen and (max-width: 980px) {
        font-size: .8em;
    }
}