/* ABOUT */

.about-wrapper {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 15% 85%;
    height: 100vh;
    overflow: hidden;
    align-items: center;

    @media screen and (max-width: 980px) {
        display: block;
        overflow: visible;
    }
}

.image-wrapper {
    height: 80%;
    width: 100%;
    padding: 0 2em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    grid-row: 1 / span 2;

    @media screen and (max-width: 980px) {
        padding: 5em 0 0 0;
        height: auto;
    }
}

.image-wrapper h6 {
    color: rgba(255, 255, 255, 0.5);;
    font-size: 1em;
    margin: 0 0 2em 0;
}

.social-link-wrapper.about {
    margin-bottom: 1em;
}

.about-details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: white;
    font-family: Cabin;
    margin-top: 2em;
}

.about-details h3 {
    font-size: 4em;
    margin: .2em 0;
    text-transform: uppercase;

    background-image: linear-gradient(to right, rgb(110, 233, 96), rgb(239, 237, 113));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}

.self-portrait {
    width: 20em;
    height: 20em;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid white;

    @media screen and (max-width: 980px) {
        width: 15em;
        height: 15em;
    }
}

.about-social-link-wrapper {
    display: flex;
    margin: 1em 0;
}

.social-link {
    height: 3em;
    width: 3em;
    background-color: black;
    margin: 0 .5em;
}

.about-text-wrapper {
    padding: 0 2em 2em 2em;
    font-size: 2em;
    height: 100%;
    overflow-y: scroll;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 700;
    color: rgba(255, 255, 255, 0.5);
    grid-row: 2;
    grid-column: 2;

    @media screen and (max-width: 980px) {
        margin: 0;
        height: auto;
        overflow-y: visible;
        padding: 0 1em 2em 1em;
        text-align: center;
    }
}

.about-text-wrapper::-webkit-scrollbar {
    width: 8px;
}

.about-text-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.294)
}

.about-text-wrapper::-webkit-scrollbar-track {
    background-color: rgb(34, 34, 34);
}

/* For Firefox */
.about-text-wrapper scrollbar {
    width: 8px;
}

.about-text-wrapper scrollbar-thumb {
    background-color: rgb(34, 34, 34);
}

.about-text-wrapper scrollbar-track {
    background-color: rgb(34, 34, 34);
}

.about-text-wrapper p {
    margin: 0;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
}

.about-text-wrapper span {
    color: white;
    font-weight: 900;
    font-family: Cabin;
    letter-spacing: .06em;
}

.about-text-wrapper strong {
    color: white;
    font-weight: 900;
}

.block-header {
    font-family: Cabin;
    color: white;
    text-align: center;
    font-size: 1.5em;
    margin: 0 0 1em 0;
}

.languages-block {
    margin: 1.5em 0;
}

.language-icons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 980px) {
        justify-content: center;
    }
}

.language-icons img {
    margin: 1em;

    @media screen and (max-width: 980px) {
        height: 2.5em;
        margin: .5em;
    }
}

.experience-years {
    text-align: center;
    font-family: Cabin;
    font-size: 3em;
    margin: .2em 0;
    background-image: linear-gradient(to right, rgb(110, 233, 96), rgb(239, 237, 113));
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
}