.nav-link-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    text-transform: uppercase;
    margin: 1em 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 5;

    @media screen and (max-width: 980px) {
        display: none;
    }
}

.nav-link a {
    padding: 0 2em;
    color: rgba(255, 255, 255, 0.5)  !important;
    text-decoration: none;
    font-family: Cabin;
    transition: all .2s ease-in-out;
}

.nav-link a:hover {
    color: white !important;
}


.nav-handle {
    height: 2em;
    /* width: 4em; */
    background-color: rgb(255, 255, 255);
}

/* Mobile */

.mobile-icon {
    position: fixed;
    top: .5em;
    right: 1em;
    height: 3em;
    width: 3em;
    z-index: 301;
    display: none;
    transition: all .2s ease-in-out;
    border-radius: 50%;
    padding: .5em;

    @media screen and (max-width: 980px) {
        display: block;
    }
}

.mobile-icon:hover {
    background-color: rgba(128, 128, 128, 0.455);
    cursor: pointer;
}

.mobile-overlay {
    background-color: white;
    height: 100%;
    width: 100vw;
    position: fixed;
    z-index: 300;
    display: none;
    overflow: hidden;
    top: 0;
}

.mobile-nav-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
    padding: 0;
    height: 100%;
}

.mobile-nav-link-wrapper .mobile-nav-link a {
    color: #2f2f34 !important;
    text-decoration: none;
    font-size: 2.5em;
    font-family: Cabin;
    font-weight: 500;
    text-transform: uppercase;
    transition: all .2s ease;
}

.mobile-nav-link-wrapper .mobile-nav-link a:hover {
    text-decoration: underline;
}

.mobile-nav-link {
    margin: 1em 0;
}