:root {
  --slate: rgb(40, 41, 39);
  --green: rgb(110, 233, 96);
  --yellow: rgb(239, 237, 113);
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--slate);
}

html {
  scroll-behavior: smooth;
}

/* Footer */

.footer-return {
  font-family: Cabin;
  display: block;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  width: 100%;
  bottom: 0;
  margin: 0;
  padding: 1em 0;
  background-color: var(--green);
  background: var(--green);
  background: linear-gradient(90deg, var(--green) 0%, var(--yellow) 100%);
  text-decoration: none;
  color: var(--slate);
}